.canceled {
  background-color: #a9a9a9 !important;
  text-decoration: line-through;
}
.ag-theme-balham,
.ag-theme-balham-dark {
  --ag-icon-font-family: agGridMaterial;
}
.ag-theme-balham .group-row.group-level-0 {
  background-color: #396397;
  color: white;
  font-weight: bold;
}

.ag-theme-balham .ag-cell-value {
  line-height: 1.1;
  word-break: normal; /* prevent words from breaking */
  padding-bottom: 5px; /* space bottom */
}

.ag-group-value {
  padding-top: 7px;
}

.ag-theme-balham .group-row.group-level-0 .ag-icon-tree-open {
  color: white;
}

.ag-theme-balham .group-row.group-level-0 .ag-icon-tree-closed {
  color: white;
}

.ag-theme-balham .group-row.group-level-1 {
  background-color: #a1b7d3;
  color: black;
  font-weight: bold;
}

.ag-theme-balham .group-row.group-level-1 .ag-icon-tree-open {
  color: black;
}

.ag-theme-balham .group-row.group-level-1 .ag-icon-tree-closed {
  color: black;
}
.ag-theme-balham .group-row.group-level-2 {
  background-color: #cbdcf0;
  color: black;
  font-weight: bold;
}

.ag-theme-balham .group-row.group-level-2 .ag-icon-tree-open {
  color: black;
}

.ag-theme-balham .group-row.group-level-2 .ag-icon-tree-closed {
  color: black;
}

.ag-theme-balham .group-row.group-level-3 {
  background-color: #d1ebf5;
  color: black;
  font-weight: bold;
}

.ag-theme-balham .group-row.group-level-3 .ag-icon-tree-open {
  color: black;
}

.ag-theme-balham .group-row.group-level-3 ag-icon-tree-closed {
  color: black;
}
.ag-row-hover:hover {
  color: black !important;
}
.group-row-drag {
  cursor: grab !important;
}
.group-row {
  display: flex;
  padding-left: 10px;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.toolbarButtons {
  margin-bottom: 5px !important;
  margin-right: 2px !important;
  padding: 2px !important;
}
.hide-header .ag-root .ag-header {
  display: none;
}
.hide-header-text .ag-header-cell-text {
  display: none;
}
.ag-header .ag-header-cell {
  padding: 3px;
}

.ag-header-cell-text {
  font-weight: bold;
}

.clickable-icon {
  cursor: pointer;
}
.gridTitle {
  font-weight: 300;
  font-size: 20px;
  font-family: Roboto, Helvetica, sans-serif;
}
.gridSubtitle {
  font-weight: 200;
  font-size: 14px;
  font-style: italic;
  font-family: Roboto, Helvetica, sans-serif;
}

.dark-header {
  --ag-header-foreground-color: white;
  --ag-header-background-color: #00213a;
  --ag-header-cell-hover-background-color: #000000;
}
.dark-header .ag-header-cell-text {
  font-size: 14px;
}
.heatMap-red {
  background-color: #ff7f50;
}
.heatMap-yellow {
  background-color: #ffff00;
}
.heatMap-green {
  background-color: #8fbc8f;
}
.editable {
  background-color: #f5f5d0;
}

.right-align {
  text-align: right;
}
.vertical-grid-lines {
  border-right: solid 0.5px #aaaaaa;
  border-left: solid 0.5px #aaaaaa;
}
.ag-theme-balham .ag-filter-toolpanel-header {
  color: black;
}
.ag-theme-balham .ag-sticky-bottom {
  font-weight: bold;
}

.disable-user-select .ag-selectable {
  user-select: none !important;
}

.ag-dnd-ghost {
  pointer-events: none;
}
